<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas="breadcrumb_data" />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("general_management.currencies_title") }}
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Dropdown-->
              <div class="dropdown dropdown-inline mr-2">
                <button
                  type="button"
                  class="btn btn-light-primary font-weight-bolder dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="svg-icon svg-icon-md">
                    <!--begin::Svg Icon | path:assets/media/svg/icons/Design/PenAndRuller.svg-->
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <rect x="0" y="0" width="24" height="24" />
                        <path
                          d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                          fill="#000000"
                          opacity="0.3"
                        />
                        <path
                          d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                          fill="#000000"
                        />
                      </g>
                    </svg>
                    <!--end::Svg Icon--> </span
                  >{{ $t("dt_table.export") }}
                </button>
                <!--begin::Dropdown Menu-->
                <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                  <!--begin::Navigation-->
                  <ul class="navi flex-column navi-hover py-2">
                    <li
                      class="navi-header font-weight-bolder text-uppercase font-size-sm text-primary pb-2"
                    >
                      {{ $t("dt_table.choose_an_option") }}:
                    </li>
                    <li class="navi-item">
                      <a href="" id="printExport" class="navi-link">
                        <span class="navi-icon">
                          <i class="la la-print"></i>
                        </span>
                        <span class="navi-text">{{
                          $t("dt_table.print")
                        }}</span>
                      </a>
                    </li>
                    <li class="navi-item">
                      <a href="" id="copyExport" class="navi-link">
                        <span class="navi-icon">
                          <i class="la la-copy"></i>
                        </span>
                        <span class="navi-text">{{ $t("dt_table.copy") }}</span>
                      </a>
                    </li>
                    <li class="navi-item">
                      <a href="" id="excelExport" class="navi-link">
                        <span class="navi-icon">
                          <i class="la la-file-excel-o"></i>
                        </span>
                        <span class="navi-text">{{
                          $t("dt_table.excel")
                        }}</span>
                      </a>
                    </li>
                    <li class="navi-item">
                      <a href="" id="csvExport" class="navi-link">
                        <span class="navi-icon">
                          <i class="la la-file-text-o"></i>
                        </span>
                        <span class="navi-text">{{ $t("dt_table.csv") }}</span>
                      </a>
                    </li>
                    <li class="navi-item">
                      <a href="" id="pdfExport" class="navi-link">
                        <span class="navi-icon">
                          <i class="la la-file-pdf-o"></i>
                        </span>
                        <span class="navi-text">{{ $t("dt_table.pdf") }}</span>
                      </a>
                    </li>
                  </ul>
                  <!--end::Navigation-->
                </div>
                <!--end::Dropdown Menu-->
              </div>
              <!--end::Dropdown-->
            </div>
          </div>
          <div class="card-body">
            <div v-if="isLoading">
              <div class="text-center">{{ $t("commons.data_loading") }}...</div>
            </div>
            <table class="table table-bordered table-head-bg collapsed" style="width: 100%" ref="kt_datatable" v-else>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{{ $t("currencies_management.code") }}</th>
                  <th scope="col">{{ $t("currencies_management.currency") }}</th>
                  <th scope="col">{{ $t("currencies_management.symbol") }}</th>
                  <th scope="col">{{ $t("currencies_management.digit") }}</th>
                  <th scope="col">{{ $t("currencies_management.purchase_try") }}</th>
                  <th scope="col">{{ $t("currencies_management.sell_try") }}</th>
                  <th scope="col">{{ $t("currencies_management.min") }}</th>
                  <th scope="col">{{ $t("currencies_management.max") }}</th>
                  <th scope="col">{{ $t("currencies_management.balance") }}</th>
                  <th scope="col">{{ $t("currencies_management.deposit") }}</th>
                  <th scope="col">{{ $t("currencies_management.withdraw") }}</th>
                  <th scope="col">{{ $t("currencies_management.operations") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item,idx) in currencies" :key="idx">
                  <th scope="row">{{ item.id }}</th>
                  <td>{{ item.code }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.symbol }}</td>
                  <td>,{{ item.decimal > 0 ? '0'.repeat(+item.decimal) : '-'}} ({{ item.decimal }})</td>
                  <td>{{ item.buying_formatted }}</td>
                  <td>{{ item.selling_formatted }}</td>
                  <td>{{ item.min_pay }}</td>
                  <td>{{ item.max_pay }}</td>
                  <td v-if="item.balance" class="text-center">
                    <span class="badge badge-success">{{ $t('currencies_management.active') }}</span>
                    <a
                      href="javascript:;"
                      class="badge badge-light ml-1"
                      :title="$t('currencies_management.make_passive')"
                      @click="activePassiveToggle(item.id, 'make_passive')"
                    >
                      {{ $t("currencies_management.make_passive") }}
                    </a>
                  </td>
                  <td class="text-center" v-else>
                    <span class="badge badge-light">{{ $t('currencies_management.passive') }}</span>
                    <a
                      class="badge badge-success ml-1"
                      href="javascript:;"
                      :title="$t('currencies_management.activate')"
                      @click="activePassiveToggle(item.id, 'activate')"
                    >
                      {{ $t("currencies_management.activate") }}
                    </a>
                  </td>
                  <td class="text-center">
                    <span
                      class="badge"
                      :class="[ item.deposit ? 'badge-success' : 'badge-light' ]"
                      :title="item.deposit ? $t('currencies_management.active') : $t('currencies_management.passive')"
                    >
                      {{ item.deposit ? $t("currencies_management.active") : $t("currencies_management.passive") }}
                    </span>
                  </td>
                  <td class="text-center">
                    <span
                      class="badge"
                      :class="[ item.withdraw ? 'badge-success' : 'badge-light' ]"
                      :title="item.withdraw ? $t('currencies_management.active') : $t('currencies_management.passive')"
                    >
                      {{ item.withdraw ? $t("currencies_management.active") : $t("currencies_management.passive") }}
                    </span>
                  </td>
                  <td class="text-center">
                    <button
                      v-if="checkPermission('crm.finance.currencies.edit')"
                      @click.prevent="goToEdit(item.id)"
                      class="btn btn-icon btn-clean btn-sm"
                      :title="$t('currencies_management.edit')"
                    >
                      <i class="la la-pencil"></i>
                    </button>
                    <button
                      @click.prevent="downloadItem()"
                      v-if="false"
                      class="btn btn-icon btn-clean btn-sm"
                      :title="$t('currencies_management.prepare_report')"
                    >
                      <i class="la la-print"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Breadcrumb from "@/components/layout/Breadcrumb.vue";
// import API_OLD from '@/api/index_old';
import $ from "jquery";
import Swal from "sweetalert2";

export default {
  name: "CurrenciesList",
  data() {
    return {};
  },
  components: {
    Breadcrumb,
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.currenciesManagement.isLoading,
      currencies: (state) => state.currenciesManagement.currencies,
      permissions: (state) => state.customer.permissions,
      lang: (state) => state.global.lang,
    }),
    breadcrumb_data() {
      return {
        title: this.$t("general_management.title"),
        subtitle: [this.$t("general_management.currencies_title")],
      };
    },
  },
  methods: {
    ...mapActions("global", ["GET_PDF"]),
    ...mapActions("currenciesManagement", [
      "GET_FINANCE_CRM_CURRENCIES",
      "BALANCE_ACTIVE_PASSIVE_TOGGLE"
    ]),
    downloadItem() {
      /* let data = { url: `${API_OLD.currencies}/${id}/pdf`, label: `${id}-merchant-invoice.pdf` };
      this.GET_PDF(data); */
    },
    checkPermission(permission) {
      return this.permissions?.some(
        (item) => String(item.name) === String(permission)
      );
    },
    activePassiveToggle(id, type = 'activate') {
      if(!['make_passive', 'activate'].includes(type)) {
        type = 'activate';
      }
      const vm = this;
      Swal.fire({
        title: this.$t("commons.are_you_sure"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("commons.approve"),
        cancelButtonText: this.$t("commons.cancel"),
      }).then(function (result) {
        if (result.value) {
          vm.BALANCE_ACTIVE_PASSIVE_TOGGLE({ id, type })
        }
      });
    },
    goToEdit(id) {
      this.$router.push(`/currencies/${id}`);
    },
    intializeDtTable() {
      const self = this;
      self.GET_FINANCE_CRM_CURRENCIES().then(() => {
        self.$nextTick(function () {
          var fileName = "Currencies";
          $(self.$refs["kt_datatable"]).DataTable({
            responsive: true,
            pagingType: "full_numbers",
            dom: "Blfrtip",
            // read more: https://datatables.net/examples/basic_init/dom.html
            buttons: ["copy", "csv", "excel", "pdf", "print"].map((el) => ({
              extend: el,
              filename: fileName,
            })),
            pageLength: 10,
            initComplete: function () {
              $(".dt-buttons").hide();
            },
            lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
            columnDefs: [
              { orderable: false, targets: -1 },
              { orderable: false, targets: -4 },
              { responsivePriority: 1, targets: 0 },
              { responsivePriority: 2, targets: -1 },
              { responsivePriority: 3, targets: 9 },
            ],
            language: {
              url: `/assets/languages/datatable/${this.lang}.json`,
            },
          });
          $("#printExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-print").trigger("click");
          });
          $("#copyExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-copy").trigger("click");
          });
          $("#excelExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-excel").trigger("click");
          });
          $("#csvExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-csv").trigger("click");
          });
          $("#pdfExport").on("click", function (e) {
            e.preventDefault();
            $(".buttons-pdf").trigger("click");
          });
        });
      });
    },
  },
  created() {
    if (this.permissions?.length > 0 && !this.checkPermission("crm.finance.currencies.index")) {
      this.$router.push("/no-permission");
    }
    this.intializeDtTable();
  },
};
</script>
